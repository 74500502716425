<template>
  <div>
    <div id="body">
      <Row :gutter="8" class="m-b-10">
        <i-col span="6">
          <Input v-model.trim="query.queryString" placeholder="支持关键词查询，例如：客户、品牌、编码" />
        </i-col>
        <i-col span="6">
          <Button class="m-r-5" size="small" icon="ios-search" type="primary" @click="getBillList">搜索</Button>
          <Button type="success" @click="downloadModal=true">下载列表</Button>
        </i-col>
      </Row>
      <!-- <div style="text-align:center;padding:10px 0;">
        <h3>合同列表</h3>
      </div> -->
      <Table  stripe
        border
        :data="contractList"
        :columns="dataColumns"
        :height="clientHeight"
        :summary-method="handleSummary"
        show-summary
      ></Table>
    </div>
    <Modal v-model="downloadModal" width="800">
      <p slot="header" class="text-center">下载列表</p>
      <p style="color:red" class="p-b-10">提示：默认选择日期为一个季度日期，可自由选择该季度内所有日期进行导出。</p>
      <Row :gutter="8">
        <i-col span="12">
          <label>开始日期：</label>
          <DatePicker
            type="date"
            :clearable="false"
            :options="dateOptions"
            v-model="dowloadModel.startDate"
            placeholder="选择开始日期"
            style="width: 200px"
          ></DatePicker>
        </i-col>
        <i-col span="12">
          <label>结束日期：</label>
          <DatePicker
            type="date"
            :clearable="false"
            :options="dateOptions"
            v-model="dowloadModel.endDate"
            placeholder="选择结束日期"
            style="width: 200px"
          ></DatePicker>
        </i-col>
      </Row>
      <div slot="footer">
        <Button type="success" @click="handleDownload">导出</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
import { toMoney } from '@/utils/wnumb_own'
import { ParseDate } from '@/utils/dateFormat'
import { downloadFileRequest } from '@/utils/download'
// import { getContractBillPage } from '@/api/statement/agentbill'
import { listPublisherContractExecuteExcludeOffset } from '@/api/dw/verify'
export default {
  data () {
    return {
      query: {
        queryString: ''
        // pageNumber: 1,
        // pageSize: 10
      },
      dowloadModel: {
      },

      dateOptions: {},
      clientHeight: 0,
      total: 0,
      contractList: [],
      dataColumns: [
        { title: '编码', align: 'center', key: 'code' },
        { title: '客户', align: 'center', key: 'advertiserName' },
        { title: '品牌', align: 'center', key: 'name' },
        {
          title: '合同额',
          align: 'center',
          key: 'amount',
          render: (h, param) => {
            return h('span', toMoney(param.row.amount))
          }
        },
        {
          title: '累计上周期合同执行额',
          align: 'center',
          key: 'totalExecutedPrice',
          render: (h, param) => {
            return h('span', toMoney(param.row.totalExecutedPrice))
          }
        },
        {
          title: '当前周期执行额',
          align: 'center',
          children: [
            {
              title: '媒介使用费',
              align: 'center',
              key: 'signUsePrice',
              render: (h, param) => {
                return h('span', toMoney(param.row.signUsePrice))
              }
            },
            {
              title: '制作费',
              align: 'center',
              key: 'producePrice',
              render: (h, param) => {
                return h('span', toMoney(param.row.producePrice))
              }
            },
            {
              title: '上刊费',
              align: 'center',
              key: 'installPrice',
              render: (h, param) => {
                return h('span', toMoney(param.row.installPrice))
              }
            },
            {
              title: '小计',
              align: 'center',
              key: 'subtotal',
              render: (h, param) => {
                return h('span', toMoney(param.row.subtotal))
              }
            }
          ]
        },
        // {
        //   title: '剩余未执行',
        //   align: 'center',
        //   key: 'breakScheduleExecutedAmount',
        //   render: (h, param) => {
        //     return h('span', toMoney(param.row.breakScheduleExecutedAmount))
        //   }
        // },
        {
          title: '操作',
          align: 'center',
          render: (h, param) => {
            return h(
              'a',
              {
                on: {
                  click: () => {
                    this.$store.commit('set_reconciliation_pageType', 'statementContractDetail1')
                    this.$store.commit('set_reconciliation_contractQuery', { id: param.row.subjectId, versionId: param.row.versionId })
                  }
                }
              },
              '详情'
            )
          }
        }
      ],
      downloadModal: false
    }
  },
  created () {
    const that = this
    that.query.companyId = this.bill.companyId
    that.query.publisherId = this.bill.publisherId
    that.query.startDate = this.bill.startDate
    that.query.endDate = this.bill.endDate
    that.dowloadModel.startDate = this.bill.startDate
    that.dowloadModel.endDate = this.bill.endDate
    that.getBillList()
    that.clientHeight = document.documentElement.clientHeight - 260

    that.dateOptions = {
      disabledDate (date) {
        return (
          (date &&
            date.valueOf() <
              new Date(that.query.startDate).valueOf() - 86400000) ||
          (date && date.valueOf() > new Date(that.query.endDate).valueOf())
        )
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      window.onresize = () => {
        this.clientHeight = document.documentElement.clientHeight - 260
      }
    })
  },
  computed: {
    bill () {
      return this.$store.state.reconciliation.bill
    }
  },
  methods: {
    getBillList () {
      listPublisherContractExecuteExcludeOffset(this.query).then((res) => {
        if (res && !res.errcode) {
          this.contractList = res.map((x) => {
            const subtotal = x.signUsePrice + x.producePrice + x.installPrice
            return Object.assign(x, { subtotal })
          })
          // this.query.pageNumber = res.pageNumber
          // this.total = res.totalRow
        } else {
          this.$Notice.error({ title: '获取合同列表出错', desc: res.errmsg })
        }
      })
    },
    handleSummary ({ columns, data }) {
      const sums = {}
      columns.forEach((column, index) => {
        const key = column.key
        if (index === 0) {
          sums[key] = {
            key,
            value: '合计'
          }
          return
        }
        const values = data.map((item) => Number(item[key]))
        if (!values.every((value) => isNaN(value))) {
          const v = values.reduce((prev, curr) => {
            const value = Number(curr)
            if (!isNaN(value)) {
              return prev + curr
            } else {
              return prev
            }
          }, 0)
          sums[key] = {
            key,
            value: toMoney(v)
          }
        } else {
          sums[key] = {
            key,
            value: '-'
          }
        }
      })

      return sums
    },
    handleDownload () {
      // const sDate = new Date(this.dowloadModel.startDate)
      // const eDate = new Date(this.dowloadModel.endDate)
      // this.dowloadModel.startDate = `${sDate.getFullYear()}-${(
      //   sDate.getMonth() + 1
      // )
      //   .toString()
      //   .padStart(2, '0')}-${sDate.getDate().toString().padStart(2, '0')}`
      // this.dowloadModel.endDate = `${eDate.getFullYear()}-${(
      //   eDate.getMonth() + 1
      // )
      //   .toString()
      //   .padStart(2, '0')}-${eDate.getDate().toString().padStart(2, '0')}`

      const query = {
        companyId: this.query.companyId,
        publisherId: this.query.publisherId,
        startDate: ParseDate(this.dowloadModel.startDate),
        endDate: ParseDate(this.dowloadModel.endDate),
        queryString: this.query.queryString
      }
      const filename = `权益额明细（${ParseDate(this.dowloadModel.startDate)}至${ParseDate(this.dowloadModel.endDate)}）.xls`
      downloadFileRequest(
        process.env.VUE_APP_API_URL_V2 +
          '/ooh-dw/v1/dw/verify/exportPublisherContractExecuteExcludeOffset',
        query,
        filename
      )
    }
  }
}
</script>
